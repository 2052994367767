import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Contexto } from 'src/app/api/contexto.service';
import { ServicioOverlayCargando } from 'src/app/utilerias/overlay-cargando.service';
import { EstatusVentas } from 'src/app/entidades/estatus-ventas';
import { first } from 'rxjs/operators';
import { Autenticacion } from 'src/app/autenticacion/autenticacion.service';
import { ComprasSAPDataComponent } from './Compras-SAP-data/Compras-SAP-data.component';
import * as _ from 'underscore';
import { ComprasPorEnviar } from 'src/app/entidades/ComprasPorEnviar';


export interface Tile {
    color: string;
    cols: number;
    rows: number;
    text: string;
  }


@Component({
    selector: 'app-grafica-Compras-SAP',
    templateUrl: './Compras-SAP.component.html',
    styleUrls: ['./Compras-SAP.component.scss']
})
export class ComprasSAPComponent implements OnInit {

    @Output() public ComprasSAPEvent = new EventEmitter<ComprasPorEnviar[]>();

    estatusVentas: EstatusVentas;
    
    lCompras: ComprasPorEnviar[];
    lComprasSinEnviar: ComprasPorEnviar[];
    lComprasTransito: ComprasPorEnviar[];
    lComprasEntregada: ComprasPorEnviar[];
    titulo:string;
    csemxp:number;
    cseusd:number;
    ctramxp:number;
    ctrausd:number;
    comtmxp:number;
    comtusd:number;
    isLoaded: boolean = false;




    constructor(private ctx: Contexto, public overlay: ServicioOverlayCargando, public autenticacion: Autenticacion, public ventana: MatDialog) {
        this.cargarDatos();
    }

    ngOnInit() { }

   
    cargarDatos(): void {
        this.ctx.documentosVenta
            .ObtenerOperacionResumenComprasSap()
            .pipe(first())
            .subscribe((response) => {        
                this.ctramxp=response.comprasTransitoMxp;
                this.ctrausd=response.comprasTransitoUsd;
                this.comtmxp=response.comprasEntregadasMxp;
                this.comtusd=response.comprasEntregadasUsd;  
               
                this.lCompras=response.comprasPorEnviarData;

                this.csemxp=response.comprasTransitoMxp + response.comprasEntregadasMxp;
                this.cseusd=response.comprasTransitoUsd + response.comprasEntregadasUsd;
               this.isLoaded = true;
            });           
             
    }

   
    abrirFormaSinEnviar(): void {
        this.lComprasSinEnviar=this.lCompras.filter(x=>x.estatusXmonitor =="Sin enviar");
        this.titulo="( Sin Enviar a SAP/REFERI )";
        const forma = this.ventana.open(ComprasSAPDataComponent, {
            data: { Compras: this.lComprasSinEnviar,titulo:this.titulo },
            panelClass: 'form-container',
            width: '90vw'
        });
    }
    abrirFormaTransito(): void {
        this.lComprasTransito=this.lCompras.filter(x=>x.estatusVenta != "Entregada");
        this.titulo="( Transito )";
        const forma = this.ventana.open(ComprasSAPDataComponent, {
            data: { Compras: this.lComprasTransito ,titulo:this.titulo},
            panelClass: 'form-container',
            width: '90vw'
        });
    }
    abrirFormaEntregada(): void {
        this.lComprasEntregada=this.lCompras.filter(x=>x.estatusVenta == "Entregada");
        this.titulo="( Entregada )";
        const forma = this.ventana.open(ComprasSAPDataComponent, {
            data: { Compras: this.lComprasEntregada ,titulo:this.titulo},
            panelClass: 'form-container',
            width: '90vw'
        });
    }
}